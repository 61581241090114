import styled from 'styled-components'
import BreakpointUp from '../Media/BreakpointUp'


export const Accordions = styled.div`  
  margin-left:20px;
  border-left:2px solid #DDE4EF;
  padding:0 0 0 40px;
  @media (min-width:992px){
    padding:0 80px 0 60px;
  }
`
export const Accordionsitems = styled.div`
  position:relative;
  background: #f8fafe;
  background: -moz-linear-gradient(top,  #f8fafe 0%, #eaeff7 47%, #eaeff7 100%);
  background: -webkit-linear-gradient(top,  #f8fafe 0%,#eaeff7 47%,#eaeff7 100%);
  background: linear-gradient(to bottom,  #f8fafe 0%,#eaeff7 47%,#eaeff7 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f8fafe', endColorstr='#eaeff7',GradientType=0 );
  border-radius:50px 10px 10px 50px;

  
  &:before{
    content: '';
    display: block;
    width:44px;
    height:80px;
    position: absolute;
    top: 0;
    background-color:#fff;
    background-image:${props => props.bgImageBefore};
    background-position: 0 20px;
    background-repeat: no-repeat;
    left:-60px;
    @media (min-width:992px){
      left:-80px;
    }
  }
  &:after{
    @media (min-width:992px){
      content: '';
      display: block;
      width:60px;
      height:80px;
      position: absolute;
      top: 0;
      right:-80px;
      background-image:${props => props.bgImageAfter};
      background-position: 0 0;
      background-repeat: no-repeat;
    }
  }
  
  &.active{
    opacity:1;
  }
  + .accordions-items{
    margin-top:20px;
    @media(min-width:576px){
      margin-top:40px;
    }
    @media(min-width:1200px){
      margin-top:60px;
    }
  }
  &.active{
    span{
      transform:rotate(225deg);
      &:before,
      &:after{
        background:#fff;
      }
    }
    .accordions-content{
      max-height:1000px;
      overflow:inherit;
      transition: max-height 0.2s ease-out;
    }
    &:before{
      opacity:1;
    }
  }
`
export const AccordionsContent = styled.div`
  max-height:0;
  overflow:hidden;
  transition: max-height .35s ease;
  position: relative;
  letter-spacing: 0.18px;
  color:#333;

  font-size:16px;
  line-height:30px; 
  ${BreakpointUp.xl`
    font-size:18px;
    line-height:32px; 
  `}
  ${BreakpointUp.xxl`
    font-size: 20px;
    line-height: 34px;
  `}
`
export const AccordionsContentinner = styled.div`
  padding:30px;
`
export const AccordionsTitle = styled.div`
  background-color:#fff;
  box-shadow:0px 10px 15px 0px rgba(194,196,200,0.2);
  cursor: pointer;
  display:flex;
  align-items:center;
  justify-content:space-between;
  position:relative;
  z-index:1;
  color: #131413;
  font-weight: 700;
  
  font-size: 18px;
  line-height:24px; 
  border-radius:30px 10px 10px 30px;  
  padding:20px;
  @media (min-width:576px){
    border-radius:50px 10px 10px 50px;  
    padding:20px 30px;
  }
  ${BreakpointUp.xl`
    font-size: 20px;
    line-height: 30px;
  `}
  ${BreakpointUp.xxl`
    font-size: 24px;
    line-height: 34px;
    padding:25px 30px;
  `}

  span{
    background-color:#161420;
    border-radius:50%;
    width:30px; 
    min-width: 30px;
    height:30px; 
    position:relative;
    display:inline-block;
    transition: all .35s ease;
    margin-left:30px;
    &:before,
    &:after{
      display:block;
      content:'';
      position:absolute;
      left:50%;
      top:50%;
      transform:translate(-50%, -50%);
      background:#fff;
    }
    &:before{
      width:2px;
      height:50%;
    }
    &:after{
      width:50%;
      height:2px;
    }
  }
`