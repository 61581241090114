import * as React from "react"
import { graphql, Link } from "gatsby"
import styled from "styled-components"
import Layout from "../components/layout"
import Seo from "../components/seo"
import {Section, Container, SectionTitle, BreadCrumb } from "../components/Section"
import { HeroBanner, HeroTitle, HeroCaption, HeroContent, HeroLeft, HeroRight, HeroCard } from "../components/HeroBanner"
import JoinForm from "../components/JoinForm"
import FaqAccordion from "../components/FaqAccordion"


const HeroTitleCustom = styled(HeroTitle)` 
  @media(min-width:1600px){
    font-size: 70px;
    line-height: 80px;
  }
`
const SectionTitleCustom = styled(SectionTitle)`
  margin-left:0;
  margin-bottom:30px;
  @media(min-width:992px){
    max-width:400px;
    margin-bottom:60px;
  }
  @media(min-width:1200px){
    max-width:530px;
  }
  @media(min-width:1366px){
    max-width:610px;
  }
`
const FaqAccordions = styled.div`       
`

const FaqPage = ({ data, location }) => {
  const pageData = data.contentfulPageFaqs;
  const faqs = data.allContentfulFaq.edges;
  return (
    <Layout location={location}>
      <Seo title={pageData.metaTitle} description={pageData.metaDescription} />
      <Section bgColor="#fff" bgBefore="#002765" bgBeforeHeight="80%"  bgBeforeWidth="100%" className="section-faq">
        <HeroBanner className="banner-hero" bgBefore="block" data-shadowtext="FAQ ">
          <HeroCaption>
            <Container maxWidth="1640px">
              <BreadCrumb><Link to='/'>Home</Link><span>FAQs</span></BreadCrumb>
              <HeroContent>
                <HeroLeft><HeroTitleCustom>{pageData.heroTitle}</HeroTitleCustom></HeroLeft>
                <HeroRight><HeroCard><JoinForm location={location} /></HeroCard></HeroRight>
              </HeroContent>
            </Container>
          </HeroCaption>
        </HeroBanner>
      </Section>
      
      <Section ept="0" epb="120px" xpt="0" xpb="80px" pt="40px" pb="40px" bgColor="#fff" className="section-accordion">
        <Container>
          <SectionTitleCustom>{pageData.faqsSectionTitle}</SectionTitleCustom>
          <FaqAccordions><FaqAccordion data={faqs} /></FaqAccordions>
        </Container>
      </Section>
    </Layout>
  );
};

export default FaqPage

export const pageQuery = graphql`
  query FaqPageQuery {
    contentfulPageFaqs {
      metaTitle
      metaDescription
      heroTitle
      faqsSectionTitle
    }
    allContentfulFaq(sort: {fields: order}) {
      edges {
        node {
          question
          answer {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`

