import React, { useState } from 'react'
import Target from "../../images/target.svg"
import Quesmark from "../../images/quesmark.svg"
import {
    Accordions,
    Accordionsitems,
    AccordionsContent,
    AccordionsContentinner,
    AccordionsTitle,
  } from "../Accordion"

const FaqAccordion = ({ data }) => {    
  const [activeFaq, setActiveFaq] = useState(null)
  // const elementRef = useRef(null);

  // console.log(elementRef.current + 100);
  function handleAccodion(key) {
    // elementRef.current?.scrollIntoView({
    //   behavior: "smooth"
    // });
    if (activeFaq === key) {
      return setActiveFaq(null)
    }
    setActiveFaq(key)
  }
	return (
    <Accordions className="accordions">
      {data.map((item, index) => (
        <Accordionsitems bgImageBefore={`url(${Target})`} bgImageAfter={`url(${Quesmark})`}
        className={`accordions-items ${activeFaq === index ? "active" : "" }`}
        key={index} 
        >
          <AccordionsTitle className="accordions-title" onClick={() => handleAccodion(index)}>{item.node.question}<span></span></AccordionsTitle>
          <AccordionsContent className="accordions-content">
            <AccordionsContentinner>
              <div
                dangerouslySetInnerHTML={{
                  __html: item.node.answer.childMarkdownRemark.html,
                }}
              />
            </AccordionsContentinner>
          </AccordionsContent>
        </Accordionsitems>
      ))}
    </Accordions>
	)
}

export default FaqAccordion
